import CustomStore from "devextreme/data/custom_store";
import DayGameService from "../../services/daygame.service";

const DayGameStore = {
    namespaced: true,

    /* State */
    state: {
        daygame: {},
        daysgames: [],
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.daygame = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.daysgames = payload;
        },
    },

    /* Actions */
    actions: {
        /*
         * Get Days
         * */
        getDaysGames({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return DayGameService.get(null, {datatable: true, ...loadOptions})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Translates
         * */
        getTranslates({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return DayGameService.post('translate', '',{datatable: true, ...loadOptions, ...payload})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_TRANSLATES', data);
        },
        /*
         * Get Translate Keys
         * */
        getTranslateKeys({commit}) {
            DayGameService.post('translate-keys')
            .then(r => {
                commit('SET_TRANSLATES_KEYS', r.data.response);
            })
        },
        /*
         * Get Day
         * */
        getDaysGame({commit}, payload) {
            DayGameService.get(payload)
            .then(r => {
                commit('SET_DATA', r.data.response);
            })
        },
        /*
         * Get Select Days
         * */
        getSelectDaysGames({commit}, payload = {}) {
            return DayGameService.get(null, payload)
            .then(r => {
                commit('SET_LIST', r.data.response);
            })
        },
        /*
         * Set Day
         * */
        setDaysGame({commit}, payload) {
            if (payload.id)
                return DayGameService.put(payload.id, payload);
            return DayGameService.post(null, payload);
        },
        /*
         * Set Translate
         * */
        setTranslate({commit}, payload) {
            return DayGameService.post('translate-change', payload);
        },
        /*
         * Action Day
         * */
        actionDayGame({commit}, payload) {
            return DayGameService.post('action', payload);
        },
        /*
         * Delete Day
         * */
        deleteDayGame({commit}, payload) {
            return DayGameService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default DayGameStore;
