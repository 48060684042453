<template>
    <div class="py-3 px-4">
        <slot></slot>
    </div>
</template>

<script>
/*
 * Import Components
 * */

export default {
    name: "CardBody",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
