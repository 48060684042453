import CustomStore from "devextreme/data/custom_store";
import degreeService from "../../services/degree.service";

const DegreeStore = {
    namespaced: true,

    /* State */
    state: {
        degree: {},
        degrees: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.degree = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.degrees = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get GameLanguages
         * */
        getDegrees({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return degreeService.get(null, {datatable: true, ...loadOptions, ...payload})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_LIST', data);
        },
        
        
        getDegree({commit}, payload) {
            return degreeService.get(payload)
            .then(r => {
                commit('SET_DATA', r.data.response);
            })
        },
        /*
         * Get Select GameLanguages
         * */
        getSelectDegrees({ commit }, payload = {}) {
            return degreeService.get(null, payload)
                .then(r => {
                    commit('SET_LIST', r.data.response);
                })
        },
        
        /*
         * Set GameLanguage
         * */
        setDegree({ commit }, payload) {
            if (payload.id)
                return degreeService.put(payload.id, payload);
            return degreeService.post(null, payload);
        },
        /*
         * Action GameLanguage
         * */
        actionDegree({ commit }, payload) {
            return degreeService.post('action', payload);
        },
        /*
         * Delete GameLanguage
         * */
        deleteDegree({ commit }, payload) {
            return degreeService.delete(payload);
        }
        
    },

    /* Getters */
    getters: {}
}

export default DegreeStore;