import CustomStore from "devextreme/data/custom_store";
import hiddenMessageService from "../../services/hidden-message.service";

const HiddenMessageStore = {
    namespaced: true,

    /* State */
    state: {
        hiddenmessage: {},
        hiddenmessages: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.hiddenmessage = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.hiddenmessages = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get hiddenmessage
         * */
        getHiddenmessages({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return hiddenMessageService.get(null, {datatable: true, ...loadOptions, ...payload})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Hiddenmessage
         * */
        getHiddenmessage({commit}, payload) {
            return hiddenMessageService.get(payload)
            .then(r => {
                commit('SET_DATA', r.data.response);
            })
        },
        /*
         * Get Select Hiddenmessage
         * */
        getSelectHiddenmessages({commit}, payload = {}) {
            return hiddenMessageService.get(null, payload)
            .then(r => {
                commit('SET_LIST', r.data.response);
            })
        },
        /*
         * Set Hiddenmessage
         * */
        setHiddenmessage({commit}, payload) {
            if (payload.id)
                return hiddenMessageService.put(payload.id, payload);
            return hiddenMessageService.post(null, payload);
        },
        /*
         * Action Hiddenmessage
         * */
        actionHiddenmessage({commit}, payload) {
            return hiddenMessageService.post('action', payload);
        },
        /*
         * Delete Hiddenmessage
         * */
        deleteHiddenmessage({commit}, payload) {
            return hiddenMessageService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default HiddenMessageStore;
