<template>
    <div class="form-error-text">
        <slot/>
    </div>
</template>

<script>
/*
 * Import Components
 * */

export default {
    name: "FormAlertError",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
