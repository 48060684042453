import CustomStore from "devextreme/data/custom_store";
import EventBoardService from "../../services/event-board.service";

const EventBoardStore = {
    namespaced: true,

    /* State */
    state: {
        event: {},
        events: [],
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.event = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.events = payload;
        },
    },

    /* Actions */
    actions: {
        /*
         * Get Events
         * */
        getEvents({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return EventBoardService.get(null, {datatable: true, ...loadOptions})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Translates
         * */
        getTranslates({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return EventBoardService.post('translate', '',{datatable: true, ...loadOptions, ...payload})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_TRANSLATES', data);
        },
        /*
         * Get Translate Keys
         * */
        getTranslateKeys({commit}) {
            EventBoardService.post('translate-keys')
            .then(r => {
                commit('SET_TRANSLATES_KEYS', r.data.response);
            })
        },
        /*
         * Get Event
         * */
        getEvent({commit}, payload) {
            EventBoardService.get(payload)
            .then(r => {
                commit('SET_DATA', r.data.response);
            })
        },

        getEventsList({commit}, payload) {
            EventBoardService.post('active-events', payload)
            .then(r => {
                commit('SET_LIST', r.data.response);
            })
        },

        /*
         * Get Select Events
         * */
        getSelectEvents({commit}, payload = {}) {
            return EventBoardService.get(null, payload)
            .then(r => {
                commit('SET_LIST', r.data.response);
            })
        },
        /*
         * Set Event
         * */
        setEvent({commit}, payload) {
            if (payload.id)
                return EventBoardService.put(payload.id, payload);
            return EventBoardService.post(null, payload);
        },
        /*
         * Set Translate
         * */
        setTranslate({commit}, payload) {
            return EventBoardService.post('translate-change', payload);
        },
        /*
         * Action Event
         * */
        actionEvent({commit}, payload) {
            return EventBoardService.post('action', payload);
        },
        /*
         * Delete Event
         * */
        deleteEvent({commit}, payload) {
            return EventBoardService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default EventBoardStore;
