// import axios from "axios";
import axios from "axios";
import CustomStore from "devextreme/data/custom_store";
import fileDownload from "js-file-download";
import accountingService from "../../services/accounting.service";

const AccountingStore = {
    namespaced: true,

    /* State */
    state: {
        totalUsers: null,
        analytic: {},
        accounting: [],
        visitors: [],
        sevenDay: [],
        all: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.analytic = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.accounting = payload;
        },
        /*
         * SET USERS
         * */
        SET_TOTAL(state, payload) {
            state.totalUsers = payload;
        },
        SET_SEVENDAY(state, payload){
            state.sevenDay = payload;
        },
        SET_VISITORS(state, payload){
            state.visitors = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get Blogs
         * */
        getAnalytics({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return accountingService.get(null, { datatable: true, ...loadOptions, ...payload })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data
                            }
                        })
                }
            })
            commit('SET_LIST', data);
        },
        
        /*
         * Get Select Blogs
         * */
        getUsers({ commit }, payload = {}) {
            return accountingService.post('active-users', payload)
                .then(r => {
                    commit('SET_TOTAL', r.data.response);
                })
        },
        /*
         * Get last Seven Day
         * */
        getSevenDay({ commit }, payload = {}) {
            return accountingService.post('seven-day', payload)
                .then(r => {
                    commit('SET_SEVENDAY', r.data.response);
                })
        },

        /*
         * Get Visitors
         * */
        getVisitors({ commit }, payload = {}) {
            return accountingService.post('visitors', payload)
                .then(r => {
                    commit('SET_VISITORS', r.data.response);
                })
        },

        /*
         * Get Visitors
         * */
        setFilter({ commit }, payload = {}) {
            return accountingService.post('filter', payload)
        },

        /*
         * Get All Reports monthly
         * */
        getAllRepoMonthly({ commit }, payload = {}) {
            // return axios.post('http://127.0.0.1:8000/api/crud/accounting/get-reports-monthly', {}, { responseType: 'blob' });
            return accountingService.get('custom_download', { responseType: 'blob' })
        },

        getAllRepoMonthlySeconWay({ commit }, payload = {}) {
            return accountingService.post('get-excel-report') 
        //     {
        //         responseType: 'blob',
        //         headers: {'Accept': 'multipart/form-data'}
        //     })
        //     .then((response) => {
        //         console.log(response.data)
        //         fileDownload(response.data, 'test.xlsx');
        //    });
        },

    },

    /* Getters */
    getters: {}
}

export default AccountingStore;