import CustomStore from "devextreme/data/custom_store";
import offerService from "../../services/offer.service";

const OfferStore = {
    namespaced: true,

    /* State */
    state: {
        offer: {},
        offers: []
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.offer = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.offers = payload;
        }
    },

    /* Actions */
    actions: {
        /*
         * Get hiddenmessage
         * */
        getOffers({commit}, payload = null) {
            const data = new CustomStore({
                load: function (loadOptions) {
                    return offerService.get(null, {datatable: true, ...loadOptions, ...payload})
                    .then(r => {
                        const data = r.data.response;
                        return {
                            data: data.data,
                            totalCount: data.total
                        }
                    })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Hiddenmessage
         * */
        getOffer({commit}, payload) {
            return offerService.get(payload)
            .then(r => {
                commit('SET_DATA', r.data.response);
            })
        },
        /*
         * Get Select Offer
         * */
        getSelectOffers({commit}, payload = {}) {
            return offerService.get(null, payload)
            .then(r => {
                commit('SET_LIST', r.data.response);
            })
        },
        /*
         * Set Offer
         * */
        setOffer({commit}, payload) {
            if (payload.id)
                return offerService.put(payload.id, payload);
            return offerService.post(null, payload);
        },
        /*
         * Action Offer
         * */
        actionOffer({commit}, payload) {
            return offerService.post('action', payload);
        },
        /*
         * Delete Offer
         * */
        deleteOffer({commit}, payload) {
            return offerService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default OfferStore;
