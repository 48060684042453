<template>
    <div class="pt-6">
        <slot/>
    </div>
</template>

<script>
/*
 * Import Components
 * */

export default {
    name: "PageBody",
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>
