import CustomStore from "devextreme/data/custom_store";
import PromoCodesService from "../../services/promo-codes.service";

const PromoCodesStore = {
    namespaced: true,

    /* State */
    state: {
        promocode: {},
        promocodes: [],
    },

    /* Mutation */
    mutations: {
        /*
         * SET DATA
         * */
        SET_DATA(state, payload) {
            state.promocode = payload;
        },
        /*
         * SET LIST
         * */
        SET_LIST(state, payload) {
            state.promocodes = payload;
        },
    },

    /* Actions */
    actions: {
        /*
         * Get promocodes
         * */
        getPromocodes({ commit }, payload = null) {
            const data = new CustomStore({
                load: function(loadOptions) {
                    return PromoCodesService.get(null, { datatable: true, ...loadOptions })
                        .then(r => {
                            const data = r.data.response;
                            return {
                                data: data.data,
                                totalCount: data.total
                            }
                        })
                }
            })
            commit('SET_LIST', data);
        },
        /*
         * Get Promocode
         * */
        getPromocode({ commit }, payload) {
            PromoCodesService.get(payload)
                .then(r => {
                    commit('SET_DATA', r.data.response);
                })
        },
        /*
         * Get Select Promocodes
         * */
        getSelectPromoCodes({ commit }, payload = {}) {
            return PromoCodesService.get(null, payload)
                .then(r => {
                    commit('SET_LIST', r.data.response.original.response);
                })
        },
        /*
         * Set User
         * */
        setPromocode({ commit }, payload) {
            if (payload.id)
                return PromoCodesService.put(payload.id, payload);
            return PromoCodesService.post(null, payload);
        },
        /*
         * Action Promocode
         * */
        actionPromocode({ commit }, payload) {
            return PromoCodesService.post('action', payload);
        },
        /*
         * Delete Promocode
         * */
        deletePromocode({ commit }, payload) {
            return PromoCodesService.delete(payload);
        }
    },

    /* Getters */
    getters: {}
}

export default PromoCodesStore;